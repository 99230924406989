import * as React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => {
    return (
        <main className="h-screen bg-[#000000] text-slate-400 flex justify-center items-center">
            <h1>404</h1>
            <Link to="/">Go Home</Link>
        </main>
    );
};

export default NotFoundPage;
